import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import * as csStyles from "../../styles/cs.module.css"

export default function Rogers() {
	return (
		<Layout>
			
			<header className={csStyles.csHeaderWrapper} id="top">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<h1>Rogers Wireless Dashboard</h1>
							<p>Improving the digital self-serve experience for wireless customers.</p>
						</div>
					</div>
				</div>
				<StaticImage src="../../images/casestudy/oct/featured-image.jpg" layout="fullWidth" className={csStyles.csFeaturedImage} alt=""/>
			</header>
			
			<main className={csStyles.csContentWrapper}>

				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Overview</h2>
							<p>Working in a team of 3 designers, my colleagues and I were tasked with the opportunity to redesign the wireless dashboard for Rogers. Our challenge was to rethink the current experience, replacing it with a more robust UX foundation to accommodate for future product evolution and features.</p>
						</div>
					</div>
				</section>
				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-4">
						<p>Test</p>
						</div>
						<div className="col-md-4">
						<p>Test</p>
						</div>
					</div>
				</section>
        
			</main>

			<div className={`container ${csStyles.csFooter}`}>
				<div className="row">
					<div className="col-md-6 col-sm-6 col-xs-6">
						<div id="previous">
							<div className="prev-project">
								<h4>↑</h4><a href="#top">Back to Top</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-6 col-xs-6">
						<div id="next">
							<div className={csStyles.nextProject}>
								<h4>Next project</h4><Link to="/work/allwell">AllWell Medical App</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

		</Layout>
	)
}
